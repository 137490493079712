import React, { Component } from 'react'

import Cart from '../components/cart'

class CartPage extends Component {

  render() {

    return (
      <>
        <Cart
          checkout={this.props.layoutState.checkout}
          loading={this.props.layoutState.cartRequest}
          updateQuantity={this.props.updateQuantity}
          removeLineItem={this.props.removeLineItem}
        />
      </>
    )
  }
}

export default CartPage
