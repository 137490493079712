import React, { Component } from 'react'

class LineItem extends Component {
  constructor(props) {
    super(props)

    this.decrementQuantity = this.decrementQuantity.bind(this)
    this.incrementQuantity = this.incrementQuantity.bind(this)
  }

  decrementQuantity(lineItemId) {
    const updatedQuantity = this.props.line_item.quantity - 1
    this.props.updateQuantity(lineItemId, updatedQuantity)
  }

  incrementQuantity(lineItemId) {
    const updatedQuantity = this.props.line_item.quantity + 1
    this.props.updateQuantity(lineItemId, updatedQuantity)
  }

  render() {
    return (
      <li className="Line-item">

        <div className="Line-item__content">
          <div className="Line-item__img">
            {this.props.line_item.variant.image ? <img src={this.props.line_item.variant.image.src} alt={`${this.props.line_item.title} product shot`}/> : null}
          </div>
          <div className="Line-item__title">
            {this.props.line_item.title}<br />
            {this.props.line_item.variant.title}
          </div>
        </div>
        <div className="Line-item__price">
          $ { this.props.line_item.variant.price }
        </div>
        <div className="Line-item__quantity">
          <div className="Line-item__quantity-container">
            <button className="Line-item__quantity-update" onClick={() => this.decrementQuantity(this.props.line_item.id)}>-</button>
            <span className="Line-item__quantity">{this.props.line_item.quantity}</span>
            <button className="Line-item__quantity-update" onClick={() => this.incrementQuantity(this.props.line_item.id)}>+</button>
          </div>
        </div>
        <div className="Line-item__total">
          <span>$ { (this.props.line_item.quantity * this.props.line_item.variant.price).toFixed(2) }</span>

          <button className="Line-item__remove" onClick={()=> this.props.removeLineItem(this.props.line_item.id)}>
            <svg width="25px" height="25px" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g id="Desktop-–-Cart" transform="translate(-1336.000000, -384.000000)" stroke="#4E4E4E">
                    <g id="Group-6" transform="translate(1337.000000, 385.000000)">
                      <g id="Group-4">
                        <g id="Group-2">
                          <circle id="Oval" cx="11.5" cy="11.5" r="11.5"></circle>
                        </g>
                      </g>
                      <g id="Group-3" transform="translate(7.000000, 7.000000)" strokeLinecap="square">
                        <path d="M4.91582062,-1.39370319 L4.91582062,10.749154" id="Line" transform="translate(4.915821, 4.677725) rotate(-45.000000) translate(-4.915821, -4.677725) "></path>
                        <path d="M5.08417938,-1.39370319 L5.08417938,10.749154" id="Line" transform="translate(5.084179, 4.677725) rotate(-315.000000) translate(-5.084179, -4.677725) "></path>
                      </g>
                    </g>
                  </g>
                </g>
            </svg>
          </button>
        </div>
      </li>
    )
  }
}

export default LineItem;
