import React, { Component } from 'react';
import { Link } from 'gatsby'
import LineItem from './line-item';

class Cart extends Component {

  openCheckout = () => {
    window.open(this.props.checkout.webUrl)
  }

  _renderLoading() {
    return (
      <div style={{ width: 50, margin: 'auto' }}>
        <svg width="50px" height="50px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" className="lds-ball" style={{background: 'none'}}>
          <circle cx="50" cy="23.0143" r="13" fill="#001D38">
            <animate attributeName="cy" calcMode="spline" values="23;77;23" keyTimes="0;0.5;1" dur="1" keySplines="0.45 0 0.9 0.55;0 0.45 0.55 0.9" begin="0s" repeatCount="indefinite"></animate>
          </circle>
        </svg>
      </div>
    )
  }

  render() {
    let line_items = this.props.checkout.lineItems.map((line_item) => {
      return (
        <LineItem
          updateQuantity={this.props.updateQuantity}
          removeLineItem={this.props.removeLineItem}
          key={line_item.id.toString()}
          line_item={line_item}
        />
      );
    });

    return (
      <div className="cart">
        <div className="cart__inner container">
          <div className="cart__header">
            <h3>Cart</h3>
            <div className="cart__button">
              <Link className="btn btn--border" to="/shop">Continue Shopping</Link>
            </div>
          </div>
          <div className="cart__titles">
            <p>Product</p>
            <p>Price</p>
            <p>Quantity</p>
            <p>Total</p>
          </div>
          <ul className="cart__line-items">
            { this.props.checkout.lineItems.length === 0 && <li className='Line-item'>{'Cart empty'}</li> }
            { line_items }
            { this.props.loading && this._renderLoading() }
          </ul>
          <div className="cart__footer">
            <div className="cart__info cart__info--small">
              Seiva process all orders in aud. While the content of your cart is currently displayed in aud, you will checkout using aud at the most current exchange rate.
            </div>
            <div className="cart__info cart__info--total">
              <div className="cart__info-pricing">
                <span className="pricing">$ {this.props.checkout.totalPrice}</span>
              </div>
              <div className="cart__info-total cart__info--small">Excluding shipping costs</div>
              <button className="cart__checkout btn btn--border" onClick={this.openCheckout}>Checkout</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Cart
